




























import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Register extends Vue {
  private loading = false;

  private isEmailTaken = false

  private validationErrors = false

  private firstName = '';
  private lastName = '';
  private email = '';
  private password = '';

  submit () {
    this.loading = true

    Services.API.Users.Register({
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      Password: this.password
    }).then((data) => {
      this.validationErrors = false

      this.loading = false

      this.isEmailTaken = data.IsEmailTaken

      if (this.isEmailTaken) return

      this.$router.push({
        path: '/account/login',
        query: {
          email: this.email
        }
      })
    }).catch(() => {
      this.loading = false

      this.validationErrors = true
    })
  }
}
